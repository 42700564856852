import React, {useState} from 'react';
import {NavLink, useLocation, useHistory} from "react-router-dom";
// @ts-ignore
import logo from "./../assets/logo.png";
import ModalBtn from "../../component/ModalBtn";
import animateScrollTo from 'animated-scroll-to';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const Header: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <>
            <div className="container__header">
                <div className="content__link-header">
                    <NavLink style={{
                        margin: 0,
                        width: '6%',
                        marginLeft: 40,
                        alignItems: 'center',
                        display: 'flex'
                    }} to={"/"}>
                        <img style={{marginLeft: 5}} src={logo} alt="logo"/>
                        مهدا
                    </NavLink>
                    <a rel="noopener noreferrer"
                       onClick={() => {
                           if (location.pathname !== '/') {
                               history.push('/');
                               setTimeout(() => {
                                   animateScrollTo(document.querySelector('.header-step') as Element)
                               }, 500)
                           } else {
                               animateScrollTo(document.querySelector('.header-step') as Element)
                           }
                       }}
                       href={"#"}>خدمات ما</a>
                    <NavLink to="/about">
                        درباره ما
                    </NavLink>
                    <NavLink to="/contact">
                        تماس با ما
                    </NavLink>
                    <a href={"https://saap.mahda.io/saap"} target={"_blank"}>
                         ساپ
                    </a>
                    <a href={"https://blog.mahda.io/"} target={"_blank"}>
                         بلاگ
                    </a>
                    <a href={"https://irexprice.mahda.io/iranianExchangesTable/"} target={"_blank"}>
                         قیمت تتر
                    </a>
                    <a href={"https://my.mahda.io/"} target={"_blank"}>
                         داشبورد
                    </a>
                </div>

                <ModalBtn/>
            </div>
        </>

    );
};
export default Header;
