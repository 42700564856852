import React from 'react';
import Header from "./Header";
import "./style.scss";
import Footer from "./Footer/Footer";

const Layout: React.FC = ({children}) => {
    return (
        <>
            <Header/>
            {children}
            <Footer />
        </>
    );
};
export default Layout;
