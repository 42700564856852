import React from 'react';
import {Switch, Route} from 'react-router-dom';
import About from "./Page/AboutUs";
import Contact from "./Page/ContactUs";
import Home from "./Page/HomePage";
import Portfolio from "./Page/Portfolio";

const App = () => {
    return (
        <Switch>
            <Route exact path={"/"} component={() => <Home/>}/>
            <Route exact path={"/about"} component={() => <About/>}/>
            <Route exact path={"/contact"} component={() => <Contact/>}/>
            <Route exact path={"/saap"} component={() => <Portfolio/>}/>
        </Switch>
    );
};

export default App;
