import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import "./styles/index.scss";
import Layout from "./Layout";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <React.Suspense fallback={<></>}>
                <Layout>
                    <App/>
                </Layout>
            </React.Suspense>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

