import React, {useEffect, useState} from 'react';
import './style.scss'
import axios from "axios";
import {toEnglishDigits} from "../../utils/Helper";
import Select from 'react-select';
import {PlusCircleOutlined} from "@ant-design/icons";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader'

const Portfolio = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [percent, setPercent] = useState<number | string>("");
    const [portfolio, setPortfolio] = useState([]);
    const [error, setError] = useState(false);
    const [validationPercent, setValidationPercent] = useState("");
    const [message, setMessage] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const initialValue = 0;
    const percentValue = portfolio.map((item: any) => item.percent);
    const sumWithInitial = percentValue.reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue),
        initialValue
    );

    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)


    const getSymbols = (e: any) => {
        if (e.length > 1) {
            axios({
                method: 'POST',
                url: 'https://saap.mahda.io/api/search_name/',
                headers: {
                    "Content-Type": "application/json",
                    "Origin": window.location.origin,
                    "access_control_request_headers": "Content-Type"
                },
                data: {
                    name: e
                }
            }).then(function (response) {
                if (response.status === 200) {
                    const res = response.data.msg.map((item: any) => ({value: item, label: item}))
                    setOptions(res)
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    };

    const submithSaap = () => {
        setLoading(true);
        setMessageError(false);
        const portfolioData = portfolio.map((item: any) => ({value: item.symbol.value, percent: item.percent}))
        axios({
            method: 'POST',
            url: 'https://saap.mahda.io/api/data/',
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Origin": window.location.origin
            },
            data: {
                name: name,
                phone: toEnglishDigits(phone),
                portfolio_data: portfolioData
            }

        }).then(function (response) {
            if (response.status === 200) {
                setMessage(true);
                setLoading(false);
                window.location.href = response.data.link
            }
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
            setMessageError(true)
        });
    };


    const handleChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
    };


    useEffect(() => {
        setPercent(100 - Number(sumWithInitial))
    }, [portfolio])


    const handleAddSymbol = () => {
        // @ts-ignore
        setPortfolio([...portfolio, {symbol: selectedOption, percent: percent}])
        setSelectedOption('')
    }


    const renderAddIcon = () => {
        const percentValidation = 100 - sumWithInitial;
        if (percentValidation >= +percent) {
            if (selectedOption) {
                return <>
                    <PlusCircleOutlined onClick={() => handleAddSymbol()} className="icon__modal-plus icon__desktop"/>
                    <button onClick={() => handleAddSymbol()} className="add__portfolio">اضافه کردن سهم</button>
                </>
            }
        }
    }

    return (
        <div className="container__form">
            <h2>ساپ، سامانه ارزیابی پرتفوی</h2>

            <LoadingOverlay
                active={loading}
                spinner={<ClockLoader color={"#fff"} />}
                text='در حال آماده سازی گزارش, لطفا منتظر باشید.'
            >

                <div style={{padding: 10}}>
                    <div className="content__form-add">
                        <div style={{width: '50%'}}>
                            <label>نام سهم</label>
                            <Select
                                value={selectedOption}
                                onChange={(e) => handleChange(e)}
                                className={"select_form-add"}
                                placeholder={"سهام"}
                                isRtl={true}
                                noOptionsMessage={() => 'لطفا تایپ کنید'}
                                onInputChange={(e) => getSymbols(e)}
                                options={options}
                            />
                        </div>
                        <div style={{width: '50%'}}>
                            <label> درصد از پرتفوی</label>
                            <input value={percent}
                                   type="text"
                                   pattern="\d*"
                                   maxLength={3}
                                   onChange={(event) => {
                                       setPercent(toEnglishDigits(event.target.value));
                                       const percentValidation = 100 - sumWithInitial;
                                       if (percentValidation < +toEnglishDigits(event.target.value)) {
                                           setValidationPercent('مجموع درصد سهام از ۱۰۰ بیشتر است، لطفا عدد کمتری وارد کنید')
                                       } else {
                                           setValidationPercent("")
                                       }
                                   }}/>

                            {validationPercent !== "" &&
                                <span style={{fontSize: 9}} className="text__validation">{validationPercent}</span>}
                        </div>

                        {renderAddIcon()}
                    </div>
                    <hr/>

                    <label>
                        <span>نام و نام خانوادگی </span>
                        <input
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                                setMessage(false);
                            }}/>
                    </label>
                    <br/>
                    <label>
                        <span>شماره تماس</span>
                        <input value={phone}
                               type={"number"}
                               onChange={(event) => {
                                   const regex = new RegExp('^(\\+98|0)?9\\d{9}$');
                                   const result = regex.test(toEnglishDigits(event.target.value));
                                   setPhone(toEnglishDigits(event.target.value));
                                   setError(!result);
                                   setMessage(false);
                               }}/>
                        {phone.length > 1 ? error &&
                            <span style={{top: 0, position: 'relative'}} className={"text__validation-mobile"}>شماره موبایل درست نیست</span> : null}

                    </label>

                    <hr/>
                    {portfolio.map((item: any, ix) => {
                        return <div className="list-portfolio" key={ix}>
                            <p>{ix + 1}</p>
                            <p>{item?.symbol?.label}</p>
                            <p>{item.percent}</p>
                        </div>
                    })}
                    {messageError && <p className="send-message-error-container">جمع درصد پورتفوی باید ۱۰۰ باشد</p> }
                    {!message ? <button style={{marginBottom: 50}}
                                        disabled={phone.length !== 11 || name === ""}
                                        onClick={() => submithSaap()}
                                        className="send-form-contact-page">بررسی و ارزیابی پرتفو</button>
                        :
                        <p className="send-message-successes-container"> گزارش برایتان پیامک خواهد شد</p>
                    }

                </div>

            </LoadingOverlay>

        </div>

    );
};
export default Portfolio;
