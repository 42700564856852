import * as React from "react";
import './style.scss';
import translate from "./home.translate.json"
import ModalBtn from "../../component/ModalBtn";

const Home: React.FC = () => {
    return <>
        <div>
            <div className="content-header-nav-link">
                <img className="image-size"
                     src={require('./images/image1.png')}
                     alt="image"/>
                <div>
                    <h2>{translate.First_Section.title_fa}</h2>
                    <p className="text-content-header">{translate.First_Section.description_fa}</p>
                    <ModalBtn homPage={true}/>
                </div>
            </div>
            <div className="content-header-nav-link bg__gray-content">
                <div>
                    <h2>{translate.Second_Section.title_fa}</h2>
                    <p className="text-content-header">{translate.Second_Section.description_fa}</p>
                </div>
                <img className="image-size"
                     style={{marginRight: '10%'}}
                     src={require('./images/image2.png')}
                     alt="image2"/>
            </div>

            <div className="container__services container__video">
                <video style={{margin: '0 auto', display: 'block', borderRadius: 15}} width={'80%'} height={'580px'} controls>
                    <source src={require('./Video/mahda.mp4')} type="video/mp4"/>
                </video>
            </div>

            <div className="header-step"/>
            <br/>
            <div style={{marginBottom: 100, marginTop: 50}} className="container__services bg__gray-content">
                <h2 style={{marginBottom: 20}}>{translate.Third_Section.title_fa}</h2>

                <div className="content__services">
                    <div className="services__box">
                        <img style={{width: '35%'}} alt={"services"} src={require('./images/service1.png')}/>
                        <h3>کربن</h3>
                        <h3>{translate.Third_Section.Services_one_title_fa}</h3>
                        <p>{translate.Third_Section.Services_one_description_fa}</p>
                    </div>
                    <div className="services__box">
                        <img style={{width: '35%'}}  alt={"services"} src={require('./images/service2.png')}/>
                        <h3>ساپ</h3>
                        <h3>{translate.Third_Section.Services_two_title_fa}</h3>
                        <p>{translate.Third_Section.Services_two_description_fa}</p>
                    </div>
                </div>
            </div>


            <div className="container__services bg__gray-content-text">
                <h2>{translate.Fourth_Section.title_fa}</h2>

                <div className="content__services">
                    <div className="services__box">
                        <img alt={"services"} src={require('./images/image4.png')}/>
                        <h3>{translate.Fourth_Section.att1_title_fa}</h3>
                        <p>{translate.Fourth_Section.att1_description_fa}</p>
                    </div>
                    <div className="services__box">
                        <img alt={"services"} src={require('./images/image5.png')}/>
                        <h3>{translate.Fourth_Section.att2_title_fa}</h3>
                        <p>{translate.Fourth_Section.att2_description_fa}</p>
                    </div>
                    <div className="services__box">
                        <img alt={"services"} src={require('./images/image6.png')}/>
                        <h3>{translate.Fourth_Section.att3_title_fa}</h3>
                        <p>{translate.Fourth_Section.att3_description_fa}</p>
                    </div>
                    <div className="services__box">
                        <img alt={"services"} src={require('./images/image7.png')}/>
                        <h3>{translate.Fourth_Section.att4_title_fa}</h3>
                        <p>{translate.Fourth_Section.att4_description_fa}</p>
                    </div>
                </div>
            </div>


            <div style={{marginBottom: 50, marginTop: 100}} className="container__services bg__gray-content">
                <h2>{translate.Fifth_Section.title_fa}</h2>

                <div className="content__services">
                    <div className="services__box">
                        <div className="circle_level bg__pa">۱</div>
                        <h3>{translate.Fifth_Section.level1_title_fa}</h3>
                        <p>{translate.Fifth_Section.level1_description_fa}</p>
                    </div>
                    <div className="services__box ">
                        <div className="circle_level bg__green">۲</div>
                        <h3>{translate.Fifth_Section.level2_title_fa}</h3>
                        <p>{translate.Fifth_Section.level2_description_fa}</p>
                    </div>
                    <div className="services__box ">
                        <div className="circle_level bg__pa">۳</div>
                        <h3>{translate.Fifth_Section.level3_title_fa}</h3>
                        <p>{translate.Fifth_Section.level3_description_fa}</p>
                    </div>
                    <div className="services__box ">
                        <div className="circle_level bg__green">۴</div>
                        <h3>{translate.Fifth_Section.level4_title_fa}</h3>
                        <p>{translate.Fifth_Section.level4_description_fa}</p>
                    </div>
                    <div className="services__box ">
                        <div className="circle_level bg__pa">۵</div>
                        <h3>{translate.Fifth_Section.level5_title_fa}</h3>
                        <p>{translate.Fifth_Section.level5_description_fa}</p>
                    </div>
                </div>
                <ModalBtn homPage={true}/>
            </div>
        </div>
    </>
};

export default Home
