import React from "react";
import {InstagramOutlined, MailOutlined, LinkedinOutlined, NotificationOutlined} from '@ant-design/icons';
import {NavLink, useHistory, useLocation} from "react-router-dom";
import animateScrollTo from "animated-scroll-to";

const Footer: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    return <>
        <div className="container-Footer">
            <div className="content"><h3>درباره ما</h3>
                <hr/>
                <p style={{textAlign: "justify"}}>
                    در ابتدای سال ۱۳۹۹ مهدا توسط جمعی از نخبگان دانشگاهی دانشگاه‌های شریف، تهران و امیرکبیر شروع به کار
                    کرد. اجرای معاملات الگوریتمی، مدیریت دارایی‌ها و پایش ریسک از مهم‌ترین محصولات مهدا هستند.
                </p>
            </div>
            <div className="content"><h3>قسمت‌های سایت</h3>
                <hr/>
                <ul>
                    <li><NavLink to="/about">درباره ‌ما</NavLink></li>
                    <li><NavLink to="/contact">تماس ‌با ما</NavLink></li>
                    <li><a rel="noopener noreferrer"   onClick={() => {
                        if (location.pathname !== '/') {
                            history.push('/');
                            setTimeout(() => {
                                animateScrollTo(document.querySelector('.header-step') as Element)
                            }, 500)
                        } else {
                            animateScrollTo(document.querySelector('.header-step') as Element)
                        }
                    }} href="#">خدمات</a></li>
                    <li><a href={"http://my.mahda.io/"} target={"_blank"}>داشبورد</a></li>
                </ul>
            </div>
            <div className="content"><h3>تماس با ما</h3>
                <hr/>
                <div className="last-section-footer">
                    <div>
                        <p>شما می‌توانید همیشه با ما در ارتباط باشید.</p>
                        <p>آدرس : تهران، لویزان، بزرگراه شهید سرلشگر بابائی، بلوار ستاد نیروی زمینی ارتش، مجتمع الماس ایران، مرکز نوآوری فردا،طبقه همکف</p>
                        <a href="mailto:info@mahda.io">ایمیل : info@mahda.io</a></div>
                        <a href="tel:02191030981">تلفن: ۹۱۰۳۰۹۸۱- ۰۲۱</a>
                    <div>
                        <a rel="noopener noreferrer" href="https://www.instagram.com/mahdaio/" target="_blank">
                            <img src={require('./../assets/instagram.png')} alt=""/>
                        </a>
                        <a rel="noopener noreferrer" href="https://www.linkedin.com/company/mahdaio/about" target="_blank">
                            <img src={require('./../assets/linkedin.png')} alt=""/>
                        </a>
                        <a rel="noopener noreferrer" href="https://twitter.com/Mahdaio" target="_blank">
                            <img src={require('./../assets/twitter.png')} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="Bottom-Footer">
            <p>© تمام حقوق و محتوا برای شرکت مهدا داده نگار‎ محفوظ است.</p>

        </div>
    </>
};

export default Footer
