import * as React from "react";
import '../style.scss';
import LinkedinOutlined from '@ant-design/icons/LinkedinOutlined';

// @ts-ignore
import image from "./assets/images/about.png"
import team from "./team.json";
import {useEffect} from "react";

const translate = require("./about.translate.json");


const About: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <div>
            <div className="container__about-top">
                <h3 className="about__text-center">{translate.Title_About.title_fa}</h3>
                <p className="about__text-center">{translate.Title_About.description_fa_2}</p>
            </div>

            <div className="container-contact-page">
                <div style={{width: "50%"}}>
                    <img style={{float: "right", marginRight: 20}} width="100%" src={image} alt={image}/>
                </div>
                <div style={{width: "50%", marginRight: 50}}>
                    <h3 className="about__text-center">درباره ما</h3>
                    <p className="about__text-center">{translate.Title_About.description_fa_1}</p>
                </div>
            </div>

            <div className="section__about bg__gray-content">
                <h2>چشم‌انداز</h2>
                <p className="about__text-bold">{translate.Title_About.description_bold_text_fa}</p>
            </div>


            <h2 className="title__about">{translate.About_Team.title_fa}</h2>

            <div className="container__team-about">
                {team.map((item, ix) => {
                    return <div key={ix} className="content__box-team">
                        <img src={require(`./assets/images/team/${item.image}`)} alt={item.name_en}/>
                        <div>
                            <p style={{marginTop: 10}}> <a href={item.linkedin} target={"_blank"}><LinkedinOutlined/></a> {item.name_fa}</p>
                            <p style={{fontSize: 12}}> <span/> {item.position}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
};

export default About
