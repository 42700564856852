import * as React from "react";
import './../style.scss';
import {useEffect, useState} from "react";
import translate from "./contact.translate.json"
import axios from "axios";
import {toEnglishDigits} from "../../utils/Helper";

const Contact: React.FC = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendRequest = () => {
        axios({
            method: 'post',
            url: 'https://mahda.io/api/users/create',
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Origin": window.location.origin
            },
            data: {
                full_name: name,
                mobile: toEnglishDigits(phone),
                description: description
            }
        }).then(function (response) {
            if (response.status === 200) {
                setMessage(true)
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    return <>
        <div className="container-contact-page">
            <div style={{width: "50%", marginLeft: 30}}>
                <img src={require('./contact.png')} alt={"contact"}/>
            </div>
            <div style={{width: "50%", marginRight: 30}}>
                <h3 className="title__contact">{translate.Title_Contact.title_fa}</h3>
                <p className="description__contact">{translate.Title_Contact.description_fa}</p>
                <div className="content__form-inline">
                    <label>
                        <span>{translate.Contact_Form.NameAndFamily_fa}</span>
                        <input
                            value={name}
                            onChange={(event) => {
                                setMessage(false);
                                setName(event.target.value)
                            }}/>
                    </label>
                    <label>
                        <span>{translate.Contact_Form.phone_fa}</span>
                        <input value={phone}
                               type={"number"}
                               onChange={(event) => {
                                   const regex = new RegExp('^(\\+98|0)?9\\d{9}$');
                                   const result = regex.test(toEnglishDigits(event.target.value));
                                   setPhone(toEnglishDigits(event.target.value));
                                   setError(!result);
                                   setMessage(false)
                               }}/>
                        {phone.length > 1 ? error &&
                            <span className={"text__validation-mobile"}>شماره موبایل درست نیست</span> : null}

                    </label>
                </div>

                <label>
                    <span>{translate.Contact_Form.message_fa}</span>
                    <textarea rows={4} value={description}
                              onChange={(event) => {
                                  setMessage(false);
                                  setDescription(event.target.value)
                              }}/>
                </label>
                {!message ?
                    <button onClick={() => sendRequest()}
                            className="send-form-contact-page">{translate.Contact_Form.sendBtn_fa}</button>
                    : <p style={{width: '100%'}} className="send-message-successes-container"> اطلاعات شما ذخیره شد.
                        کارشناسان مهدا در اسرع وقت با شما تماس خواهند گرفت</p>
                }

            </div>
        </div>
    </>
};

export default Contact
