import React, {useState} from 'react';
// @ts-ignore
import Modal from 'react-modal';
import './style.scss'
import {CloseCircleOutlined} from '@ant-design/icons';
// @ts-ignore
import translate from "./modalBtn.translate";
import axios from "axios";
import {toEnglishDigits} from "../utils/Helper";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 30,
        border: '1px solid #eee',
    },
};

interface Props {
    homPage?: boolean
}
const ModalBtn= ({homPage}: Props) => {
    let subtitle: HTMLHeadingElement | null;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // @ts-ignore
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const sendRequest = () => {
        axios({
            method: 'post',
            url: 'https://mahda.io/api/users/create',
            headers: {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Origin": window.location.origin
            },
            data: {
                full_name: name,
                mobile: toEnglishDigits(phone),
                description: ""
            }
        }).then(function (response) {
            if(response.status === 200){
                setMessage(true)
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    return (
        <>
            <button style={homPage ? {padding:'12px 50px'} : {}} className="show__modal-form" onClick={openModal}>{
                homPage ? translate.Modal.title_bottom_fa : 'درخواست مشاوره رایگان'
            }</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="فرم درخواست"
            >
                <CloseCircleOutlined onClick={closeModal} className="icon__modal-close"/>
                <p className="text-warning-modal">{translate.Modal.description_fa}</p>
                <div className="container__form-modal">
                    <label>
                        <span>نام و نام خانوادگی </span>
                        <input
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                                setMessage(false);
                            }}/>
                    </label>
                    <label>
                        <span>شماره تماس</span>
                        <input value={phone}
                               type={"number"}
                               onChange={(event) => {
                                   const regex = new RegExp('^(\\+98|0)?9\\d{9}$');
                                   const result = regex.test(toEnglishDigits(event.target.value));
                                   setPhone(toEnglishDigits(event.target.value));
                                   setError(!result);
                                   setMessage(false);
                               }}/>
                        {phone.length > 1  ? error &&  <span style={{bottom: -15}} className={"text__validation-mobile"}>شماره موبایل درست نیست</span> : null}

                    </label>
                    {!message ? <button disabled={phone.length !== 11} onClick={() => sendRequest()}
                             className="send-form-contact-page">ارسال</button>
                        : <p className="send-message-successes-container"> اطلاعات شما ذخیره شد. کارشناسان مهدا در اسرع وقت با شما تماس خواهند گرفت</p>
                    }
                </div>

            </Modal>
        </>

    );
};
export default ModalBtn;
